import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Slides from "./slides/slide";
import { FaLongArrowAltRight } from "react-icons/fa";
import { IoLocationSharp, IoCall } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { Booking } from "../Component/Booking";

function Home() {

    const options = {
        loop: true,
        margin: 10,
        nav: true,
        items: 1,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: true
    };

    return (
        <>

            {/* Hero Section Begin*/}

            <OwlCarousel className="hero-slider owl-theme" {...options}>
                <div className="item">
                    <img src={`${process.env.PUBLIC_URL}/img/Layer1.jpg`} alt="Hero 1" />
                    <div className="hero-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-xl-8 col-sm-12">
                                    <div className="hero-text">
                                        <h1>The Best Family Hotel</h1>
                                        <h2>Making Your Experience one of a kind </h2>
                                        <a href="/rooms" className="primary-btn">view Accommodation</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <img src={`${process.env.PUBLIC_URL}/img/Layer2.jpg`} alt="Hero 2" />
                    <div className="hero-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="hero-text">
                                        <h1>Experience the Finest Rooms</h1>
                                        <h2>Rooms with Feature Rich Amenities</h2>
                                        <div className='d-flex' style={{ gap: '10px' }}>
                                            <a href="/booknow" className="primary-btn">Book Now</a>
                                            <a href="/contact" className="primary-btn2">Talk to us</a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <img src={`${process.env.PUBLIC_URL}/img/Layer3.jpg`} alt="Hero 3" />
                    <div className="hero-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-sm-12">
                                    <div className="hero-text">
                                        <h1>Luxury In Your Room</h1>
                                        <h2>Ask for the best room with ultimate privacy, Feel Special  </h2>
                                        <div className='d-flex' style={{ gap: '10px' }}>
                                            <a href="/booknow" className="primary-btn">Book Now</a>
                                            <a href="/contact" className="primary-btn2">Talk to us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </OwlCarousel>
            <Booking />

            {/*Hero Section End */}

            {/*About Us Section Begin */}
            <section className="aboutus-section spad">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-7">
                            <div className="about-text">
                                <div className="section-title">
                                    <span>About Us</span>
                                    <h2>Relax in Our Place</h2>
                                </div>
                                <p className="f-para" style={{ textAlign: 'justify' }}>This would be an ideal place to
                                    spend your holidays during your leisure time. Park Paradise Rumassala gives you a
                                    calm and quiet environment that touches your heart with the serenity of human life
                                    with real nature at its core. Blessed with an acre of nature, experience the sights
                                    and sounds of Beach and have your heart touched with the serenity and luxury that
                                    Sri Lanka has to offer.</p>
                                <p className="s-para" style={{ textAlign: 'justify' }}>Guests of the hotel can enjoy
                                    on-site features like family room, Wi-Fi in public areas, 24hr room service. Guests
                                    can choose from 3 rooms, all of which exude an atmosphere of total peace and
                                    harmony. Throughout the day you can enjoy the relaxing atmosphere of the garden,
                                    outdoor pool, . Whatever your reason</p>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="about-pic">
                                <div className="row" style={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <div className="col-sm-6 col-lg-10 d-flex">
                                        <img src="img/AboutUs.png " alt="" style={{
                                            borderBottomLeftRadius: '150px',
                                            borderTopLeftRadius: '25px',
                                            borderTopRightRadius: '25px',
                                            borderBottomRightRadius: '25px'
                                        }} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*About Us Section End*/}

            {/*Services Section End*/}
            <section className="services-section spad">
                <div className='container'>
                    <div className="service-main-row row">
                        <div className="col-lg-7 col-md-12 col-sm-6">
                            <div className=" row">
                                <div className=' col-lg-4 col-md-4' style={{ padding: '0px' }}>
                                    <img width='100%' height="100%" src='img/serviceimg1.jpg' />
                                    <div style={{ width: '100%', height: '50px', backgroundColor: '#C6C3C3' }}
                                        className='d-flex justify-content-center align-items-center'>
                                        <h5 className='text-center'>Safe & Security</h5>

                                    </div>
                                </div>
                                <div className='col-md-4 col-lg-4 service-photo2' style={{ padding: '0px' }}>
                                    <img width='100%' height="100%" src='img/Loopy-Real-Estate-19-1-2.png' />
                                    <div style={{ width: '100%', height: '50px', backgroundColor: '#C6C3C3' }}
                                        className='d-flex justify-content-center align-items-center'>
                                        <h6>Comfort & Facility</h6>

                                    </div>
                                </div>

                                <div className=' col-lg-4 col-md-4' style={{ padding: '0px' }}>
                                    <img width='100%' className='' height="100%" src='img/AboutUs.png' />
                                    <div style={{ width: '100%', height: '50px', backgroundColor: '#C6C3C3' }}
                                        className='d-flex justify-content-center align-items-center'>
                                        <h6>Fun & Activities</h6>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 col-sm-6">
                            <div className="d-flex " style={{ gap: '10px', flexDirection: 'column' }}>
                                <h2 className='text-white'>In Park Paradise</h2>
                                <h4 className='text-white'>Experience hospitality redefined. We offers more than just a
                                    room - it's your gateway to thrilling experience and unforgettable memories.</h4>
                                <p>Experience unforgettable hospitality. We offer luxurious accommodations, prime
                                    location, family-friendly amenities. Relax, explore, and create lasting memories.
                                    Book your stay today!</p>
                                <a href="/booknow" className='service_btn'>Booking Now</a>
                            </div>
                        </div>

                    </div>

                </div>

            </section>
            {/*Services Section End*/}


            {/*Start of facilities section*/}
            <section className='  px-3' style={{ paddingTop: '80px' }}>
                <div className=''>
                    <div className='section-title d-flex justify-content-center'>
                        <h2>Facilities Available</h2>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='row'>

                                <div className='col-lg-12 d-flex justify-content-center'
                                    style={{ backgroundColor: '#f2f3f4' }}>
                                    <div className='d-flex align-items-center faciliti-para'
                                        style={{ marginTop: '20px', flexDirection: 'column', gap: '30px' }}>
                                        <h2 style={{ marginTop: '40px' }}>King Room with Sea view</h2>
                                        <h3 className=" faciliti-para-h3" style={{textAlign: 'start'}}>
                                            Superior sea view king bed room, are modern decorated and offering comfortable hospitality
                                            to a family with 2 children, or a company of 4 adults

                                        </h3>
                                    </div>

                                </div>
                                <div className='col-lg-12'>
                                    <div className=" row">
                                        <div className=' col-lg-4 col-md-4' style={{ padding: '0px' }}>
                                            <img width='100%' height="100%" src='img/facilities-img-2.png' />

                                        </div>
                                        <div className='col-md-4 col-lg-4 ' style={{ padding: '0px' }}>
                                            <img width='100%' height="100%" src='img/facilities-img-3.png' />

                                        </div>

                                        <div className=' col-lg-4 col-md-4' style={{ padding: '0px' }}>
                                            <img width='100%' className='' height="100%"
                                                src='img/facilities-img-4.png' />

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 ' style={{ padding: '0px' }}>
                            <img className='faciliti-last-img' width='100%' src='img/facilities-img-1.png' />
                        </div>

                    </div>
                </div>
            </section>


            <section className='spad-2'>

                <div className='section-title d-flex justify-content-center'>
                    <h2>Our Menu</h2>
                </div>

                <section className='food-section'>
                    <div className='container'>

                        <Slides />

                    </div>
                </section>
            </section>

            <section>
                <div className='container'>
                    <div className='section-title d-flex justify-content-center'>
                        <h2>News & Events</h2>
                    </div>
                    <div className='row justify-content-center news-main'>
                        <div className='col-lg-9 '>
                            <div className='row justify-content-center '>
                                <div className='news-img-div1 col-lg-5  mb-3  d-flex justify-content-center'
                                    style={{ border: '2px solid #b8a680', height: '250px', width: '100%' }}>
                                    <div className='content d-flex justify-content-center'
                                        style={{ flexDirection: 'column' }}>
                                        <p style={{ color: '#b8a680' }}>May 1,2024</p>
                                        <h4>Top things to do in Galle, Sri Lanka</h4>
                                        <div className='d-flex align-items-center justify-content-end mt-5'
                                            style={{ gap: '10px' }}>
                                            <a href='/toDo' style={{ fontSize: '13px', color: '#b8a680' }}>Read More</a>
                                            <FaLongArrowAltRight />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-5 news-img-div2  mb-3 d-flex justify-content-center'
                                    style={{ border: '2px solid #b8a680', height: '250px', width: '100%' }}>
                                    <div className='content d-flex justify-content-center'
                                        style={{ flexDirection: 'column' }}>
                                        <p style={{ color: '#b8a680' }}>March 25,2024</p>
                                        <h4>Why Rumassala is important</h4>
                                        <div className='d-flex align-items-center justify-content-end mt-5'
                                            style={{ gap: '10px' }}>
                                            <a href='/whtim' style={{ fontSize: '13px', color: '#b8a680' }}>Read More</a>
                                            <FaLongArrowAltRight />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-5 mb-3 news-img-div3 d-flex justify-content-center'
                                    style={{ border: '2px solid #b8a680', height: '250px', width: '100%' }}>
                                    <div className='content d-flex justify-content-center'
                                        style={{ flexDirection: 'column' }}>
                                        <p style={{ color: '#b8a680' }}>october 1,2023</p>
                                        <h4>When is the best time to visit Sri Lanka</h4>
                                        <div className='d-flex align-items-center justify-content-end mt-5'
                                            style={{ gap: '10px' }}>
                                            <a href='/bestTime' style={{ fontSize: '13px', color: '#b8a680' }}>Read More</a>
                                            <FaLongArrowAltRight />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-5 news-img-div4 mb-3 d-flex justify-content-center'
                                    style={{ border: '2px solid #b8a680', height: '250px', width: '100%' }}>
                                    <div className='content d-flex justify-content-center'
                                        style={{ flexDirection: 'column' }}>
                                        <p style={{ color: '#b8a680' }}>May 1,2023</p>
                                        <h4>Rumassala Mountain, Sri Lanka</h4>
                                        <div className='d-flex align-items-center justify-content-end mt-5'
                                            style={{ gap: '10px' }}>
                                            <a href='/mount' style={{ fontSize: '13px', color: '#b8a680' }}>Read More</a>
                                            <FaLongArrowAltRight />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>


            </section>

            {/*Home Room Section End */}
            {/*start where we are*/}
            <section className='spad'>
                <div className='container'>
                    <div className='row justify-content-center news-main'>
                        <div className='col-lg-10 '>
                            <div className='row justify-content-center '>
                                <div className='col-lg-10 where-div  d-flex '
                                    style={{ height: '650px', width: '100%', flexDirection: 'column', padding: '0px' }}>

                                    <div className=' d-flex align-items-center  ' style={{
                                        width: '100%',
                                        flexDirection: 'column',
                                        gap: '10px',
                                        marginTop: '80px',
                                        paddingBottom: '40px'
                                    }}>
                                        <h2 className='fw-bold' style={{ fontWeight: 'bold' }}>Where We are</h2>
                                        <div style={{ border: '1px solid black', width: '50px' }}></div>
                                    </div>
                                    <div className='where-div-last ' style={{ backgroundColor: 'black', width: '100%', height: '100%' }}>
                                        <div className='row d-flex justify-content-center'>
                                            <div className='where-div-last1 col-lg-5 col-sm-8 col-md-5' style={{
                                                backgroundColor: '#C6C3C3',

                                                border: '1px solid white'
                                            }}>
                                                <div className='pt-4 d-flex'
                                                    style={{ gap: '15px', flexDirection: 'column' }}>
                                                    <h3>Address</h3>
                                                    <h6 className='text-dark'>
                                                        We are conveniently located at with easy access to public
                                                        transportation nearby.
                                                    </h6>
                                                    <div className='d-flex' style={{ flexDirection: 'column' }}>
                                                        <div className='d-flex' style={{ gap: '10px' }}>
                                                            <IoLocationSharp style={{ color: '#6ec1e4' }} />
                                                            <p className='text-dark'>
                                                                Yaddehimulla, Rumassala, Unawatuna
                                                            </p></div>
                                                        <div className='d-flex' style={{ gap: '10px' }}>
                                                            <IoCall style={{ color: '#6ec1e4' }} />
                                                            <p className='text-dark'>
                                                                (+94) 75 610 6173
                                                            </p>
                                                        </div>
                                                        <div className='d-flex' style={{ gap: '10px' }}>
                                                            <IoMdMail style={{ color: '#6ec1e4' }} />
                                                            <p className='text-dark'>
                                                                info@parkparadiserumassala.com
                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='where-div-last1 col-lg-5 col-sm-8 col-md-5' style={{
                                                backgroundColor: '#C6C3C3',
                                                border: '1px solid white'
                                            }}>
                                                <div className='pt-4 d-flex'
                                                    style={{ gap: '15px', flexDirection: 'column' }}>
                                                    <h3>Opening Hours
                                                    </h3>
                                                    <h6 className='text-dark'>
                                                        Our dedicated staff is available 24/7 to ensure a comfortable
                                                        and relaxing stay.
                                                    </h6>
                                                    <div className='d-flex' style={{ flexDirection: 'column' }}>
                                                        <div className='d-flex' style={{ gap: '45px' }}>
                                                            <p className='text-dark'>Monday - Friday</p>
                                                            <div
                                                                className='d-flex justify-content-center align-items-center'
                                                                style={{
                                                                    width: '100px',
                                                                    height: '30px',
                                                                    backgroundColor: '#b8a680'
                                                                }}>
                                                                <p className='mb-0'>7.00-10.00</p>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex' style={{ gap: '30px' }}>
                                                            <p className='text-dark'>
                                                                Saturday - Sunday
                                                            </p>
                                                            <div
                                                                className='d-flex justify-content-center align-items-center'
                                                                style={{
                                                                    width: '100px',
                                                                    height: '30px',
                                                                    backgroundColor: '#b8a680'
                                                                }}>
                                                                <p className='mb-0'>12.00-6.00</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </section>
            {/*end where we are*/}


            {/*Search model Begin*/}

            <div className="search-model">
                <div className="h-100 d-flex align-items-center justify-content-center">
                    <div className="search-close-switch"><i className="icon_close"></i></div>
                    <form className="search-model-form">
                        <input type="text" id="search-input" placeholder="Search here....." />
                    </form>
                </div>
            </div>
            {/*Search model end*/}
        </>
    );
}

export default Home;