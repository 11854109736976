import React from "react";

function WhyImportantRumassala() {
    return (
        <>
            <div className="container-fluid header-bg py-5 mb-5 wow fadeIn header_bg-whyImportant"
                 data-wow-delay="0.1s">
                <div className="container py-5 d-flex justify-content-start" style={{flexDirection: 'column'}}>
                    <h3 className="display-4 text-white mb-3 animated slideInDown"
                        style={{fontSize: '40px'}}>When is the best time to visit Sri Lanka?

                    </h3>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                            <li className="breadcrumb-item"><a className="text-white" href="/">News</a></li>
                            <li className="breadcrumb-item text-primary active" aria-current="page">When is the best
                                time to visit Sri Lanka?
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className='spad3'>
                <div className='container'>
                    <div className='row  d-flex justify-content-center'>
                        <div className='col-lg-10 col-xl-9 col-md-12' >
                            <div className='row   justify-content-center'>
                                <div className='col-lg-12 col-md-12 col-sm-8 d-flex justify-content-center py-5 '
                                     style={{flexDirection: 'column', gap: '5px',}}>
                                    <h3>When is the best time to visit Sri Lanka?</h3>
                                    <img src='img/blog/beach.jpg' className='mt-2' width='100%'/>
                                    <div className='d-flex flex-column mt-4'>

                                        <p>
                                            Sri Lanka is a year-round holiday destination thanks to its combination of
                                            weather systems ensuring that one or several regions, as well as a section
                                            of coastline, is statistically always expecting sunshine.

                                        </p>
                                        <p>Whilst the best time to go to Sri Lanka is between December and mid-April,
                                            which is considered to be the countrywide peak season, the traditional
                                            summer holiday months are also favourable on the east coast which is at its
                                            peak from May to September.

                                        </p>
                                        <p>
                                            Temperatures are fairly constant year round, with coastal regions enjoying average temperatures of 25-30°C and the highlands 15-18°C on average. If you’re travelling during the summer months (May to September) you’ll want to head towards the east coast and northern regions for dry, sunny weather, as the west and south west coasts experience rain at this time. On the flip side, the west and south west enjoy favourable weather conditions during the winter months (October to February) when the monsoon season hits the east and north.


                                        </p>
                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </section>

        </>
    )
}

export default WhyImportantRumassala