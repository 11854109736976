import React from "react";

function RumassalaMountain() {
    return (
        <>
            <div className="container-fluid header-bg py-5 mb-5 wow fadeIn header_bg-whyImportant"
                 data-wow-delay="0.1s">
                <div className="container py-5 d-flex justify-content-start" style={{flexDirection: 'column'}}>
                    <h3 className="display-4 text-white mb-3 animated slideInDown"
                        style={{fontSize: '40px'}}>Rumassala Mountain Sri Lanka


                    </h3>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                            <li className="breadcrumb-item"><a className="text-white" href="/">News</a></li>
                            <li className="breadcrumb-item text-primary active" aria-current="page">Rumassala Mountain
                                Sri Lanka

                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className='spad3'>
                <div className='container'>
                    <div className='row  d-flex justify-content-center'>
                        <div className='col-lg-10 col-xl-9 col-md-12'>
                            <div className='row   justify-content-center'>
                                <div className='col-lg-12 col-md-12 col-sm-8 d-flex justify-content-center py-5 '
                                     style={{flexDirection: 'column', gap: '5px',}}>
                                    <h3>Rumassala mountain Sri Lanka: The Mountain that fell from the sky
                                    </h3>
                                    <img src='img/blog/rumassala.png' className='mt-2' width='100%'/>
                                    <div className='d-flex flex-column mt-4'>
                                        <p>
                                            What is Rumassala Mountain and what can I see there?</p>
                                        <p className='mt-2'>
                                            Rumassala was formerly known as ‘Buona Vista’. It is a rain-forested
                                            promontory which offers an exceptional view of Galle from its hilltop. The
                                            forest and the ocean around Rumassala are rich in biodiversity; apart from
                                            the endemic and unique plants and herbs atop Rumassala, the waters beneath
                                            the base of the cliff are home to many sea-lives as well!

                                        </p>
                                        <p>At the top of Rumassala Hill is the Peace Pagoda built under the guidance of
                                            the founder of the Nipponzan Myohoji Nikaya in Japan. There is also a very
                                            old institution of learning, named ‘The Buona Vista School’, which possesses
                                            an impressive history and a Buddhist Theme Park which is developing around
                                            an Elders Home in Rumassala. These are must visits on your Rumassala tour.
                                            Amongst the attractions atop Rumassala mountain are a large statue of
                                            Buddha, edifices depicting the arrival of Buddhism in Sri Lanka and the
                                            brining of the Sacred Bo Tree from India, a statue of Anagarika Dharmapala
                                            and more.

                                        </p>

                                    </div>
                                    <div className='d-flex flex-column mt-4'>
                                        <p>
                                            What’s the story? </p>
                                        <p className='mt-2'>
                                            The Rumassala Mountain is directly linked to the Ramayana yatra. According
                                            to the epic Sanskrit tale, the Ramayana, the re-incarnated god Lord Rama’s
                                            wife was kidnapped and and taken away by King Ravana. Thereafter, having
                                            searched long and hard, Lord Rama traced Sita to Sri Lanka, with the help of
                                            an immortal monkey demi-god named Hanuman and Rama’s brother, Lakshmana. An
                                            entire army of men and monkeys headed by Rama, Lakshmana, and Hanuman waged
                                            war on Ravana’s kingdom to rescue the kidnapped princess.
                                            During this war, Lakshmana was injured by powerful arrows and fell
                                            unconscious. Hanuman was tasked with fetching lifesaving herbs from Himalaya
                                            to save Lakshmana’s life. So Hanuman went to the hills, lifted the part of
                                            the hill and brought it to Sri Lanka as he was unable to identify the
                                            lifesaving herbs alone. While he was carrying the hill, it is believed that
                                            some parts from the hill fell down at five places in Sri Lanka, one of them
                                            being Rumassala. It is said that the monkey-god made it back in time to save
                                            Lakshmana, the battle was won by Lord Rama, and Sita was rescued
                                            <br/>Is there any truth to this story?
                                        </p>

                                        <p>It was discovered that the area has a magnetic anomaly, consistent with being
                                            struck by a meteorite by Arthur C. Clarke, a famous science visionary of Sri
                                            Lanka. According to Clarke, the scientific explanation could thus be that it
                                            is actually a meteorite that fell upon earth. It could mean that the
                                            exceptionally exotic and unique plant life of the hill could be from another
                                            space and plain. Further, according to Clarke, Rumassala has one of the
                                            lowest gravitational fields on earth, resulting in satellites being lost in
                                            orbit when they pass overhead.
                                            Rumassala is a haven for medicinal and other herbs of value. Also, the soil
                                            properties of the hill are inherently different to the surrounding landscape
                                            which supports the legend that the hill was transported from another
                                            geographical location. Science has failed to logically explain the
                                            intriguing features of Rumassala and the legend of Rumassala is only further
                                            strengthened by these unexplained mysteries surrounding the hill.

                                        </p>

                                    </div>


                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </section>

        </>
    )
}

export default RumassalaMountain