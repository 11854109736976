import React, {useState} from "react";
import FacilitySlider from "./slides/FacilitySlider";
import FacilitySlidesSec from "./slides/FacilitySlidesSec";
import FacilitySlideThird from "./slides/FacilitySlideThird";
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaServicestack , FaUser , FaHotel  } from 'react-icons/fa';
import { RiHotelFill } from "react-icons/ri";



const ServiceCard = ({ title, icon, subtitle }) => (
    <Card className="text-center mb-4 w-100" style={{opacity:'0.9'}}>
        <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Img variant="top" src={icon} style={{ width: '50px', marginBottom: '10px' }} />

            <Card.Text>{subtitle}</Card.Text>
        </Card.Body>
    </Card>
);
function Facilities() {
    const [hover, setHover] = useState(false);
    const [hover1, setHover1] = useState(false);
    const [hover2, setHover2] = useState(false);
    const [hover3, setHover3] = useState(false);





    return (
        <>
            <div className="container-fluid header-bg py-5 mb-5 wow fadeIn header_bg-about2" data-wow-delay="0.1s">
                <div className="container py-5 d-flex justify-content-start" style={{flexDirection: 'column'}}>
                    <h3 className="display-4 text-white mb-3 animated slideInDown"
                        style={{fontSize: '40px'}}>Facilities </h3>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                            <li className="breadcrumb-item text-primary active" aria-current="page">Facilities</li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/*start of slide*/}
            <section className=''>
                <div className='container1'>
                    <FacilitySlider/>

                </div>

            </section>
            <section className=''>
                <div className='container1'>
                    <FacilitySlidesSec/>

                </div>

            </section>
            <section className=''>
                <div className='container1'>
                    <FacilitySlideThird/>

                </div>

            </section>
            {/*end of slide*/}

            {/*start of common facilities*/}
            <section className='spad' >
                <div className='container-fluid' >

                    <div className='row '  >
                        <div className='col-lg-6 col-sm-12 common-faci-div ' >
                            <div className='mt-4'>

                                <Row className="justify-content-center align-items-end">
                                    <Col lg={5} xl={5} md={5}>
                                        <ServiceCard
                                            title="HD Television"
                                            icon="img/services/telvision.png" // replace with your icon path
                                            subtitle="Satellite"
                                        />
                                    </Col>
                                    <Col lg={5} xl={5} md={5}>
                                        <ServiceCard
                                            title="Telephone"
                                            icon="img/services/tel-phone.png" // replace with your icon path
                                            subtitle="Land Line"
                                        />
                                    </Col>
                                </Row>
                                <Row className="justify-content-center align-items-end ">
                                    <Col lg={5} xl={5} md={5}>
                                        <ServiceCard
                                            title="Laundry"
                                            icon="img/services/fax.png" // replace with your icon path
                                            subtitle="Machine & Dry Clean"
                                        />
                                    </Col>
                                    <Col lg={5} xl={5} md={5}>
                                        <ServiceCard
                                            title="BBQ"
                                            icon="img/services/out.png" // replace with your icon path
                                            subtitle="Machine"
                                        />
                                    </Col>
                                </Row>

                            </div>
                        </div>
                        <div className='col-lg-6  common-faci-div1  ' style={{backgroundColor:'#000000'}} >
                            <div className='d-flex p-5' style={{flexDirection:'column',gap:'20px'}}>
                                <h2 className='text-white fw-bold'>Common Facilities</h2>
                                <h5 className='text-white fw-bold'>
                                    At Park Paradise, we believe your comfort and well-being are paramount. That's why we offer a range of exceptional facilities designed to enhance your stay.
                                </h5>
                                <p>
                                    We offers everything you need for a truly unforgettable stay. Browse our facilities and start planning your perfect getaway today!
                                </p>
                                <a href="#" className='facilities-btn '>Read more</a>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*end of common facilities*/}

            {/*start of  our services*/}
            <section className=''>
                <div className='container'>
                    <div className='row  d-flex justify-content-center'>
                        <div className='col-lg-9'>
                            <h2 className='text-center'>Room Facilities</h2>
                            <div className='row  '>
                                <div className='col-lg-12 col-md-6 col-sm-8 mt-4 d-flex justify-content-start align-items-center' style={{
                                    flexDirection: 'column',
                                    gap: '5px',

                                }}>
                                    <div className='row'>
                                        <div className='col-lg-4 d-flex flex-column  justify-content-start' style={{gap:'10px'}}>
                                            <div className='rounded-circle  d-flex icon-round justify-content-center align-items-center' style={{width:'54px',height:'54px'}}>
                                                <img src='img/services/swimtub.png' style={{color:'white'}}/>
                                            </div>
                                            <h6>Swim Pool</h6>
                                            <p>
                                                It's the perfect place to refresh, recharge, and create unforgettable memories during your stay in Unawatuna!
                                            </p>

                                        </div>
                                        <div className='col-lg-4 d-flex flex-column  justify-content-start' style={{gap:'10px'}}>
                                            <div className='rounded-circle d-flex justify-content-center icon-round align-items-center' style={{width:'54px',height:'54px'}}>
                                                <img src='img/services/thambles.png' style={{color:'white'}}/>
                                            </div>
                                            <h6>Gymnasium</h6>
                                            <p>
                                                Our prime location in Unawatuna positions you just a stone's throw away from a well-equipped gymnasium.                                            </p>

                                        </div>
                                        <div className='col-lg-4 d-flex flex-column  justify-content-start' style={{gap:'10px'}}>
                                            <div className='rounded-circle d-flex justify-content-center icon-round align-items-center' style={{width:'54px',height:'54px'}}>
                                                <img src='img/services/projector.png' style={{color:'white'}}/>
                                            </div>
                                            <h6>Projection</h6>
                                            <p>
                                                We utilizes comprehensive hotel projection tools to forecast future occupancy rates, room revenue, and overall performance.                                            </p>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-12 col-md-6 col-sm-8 mt-4 d-flex justify-content-start align-items-center' style={{
                                    flexDirection: 'column',
                                    gap: '5px',

                                }}>
                                    <div className='row'>
                                        <div className='col-lg-4 d-flex flex-column  justify-content-start' style={{gap:'10px'}}>
                                            <div className='rounded-circle d-flex icon-round justify-content-center align-items-center' style={{width:'54px',height:'54px'}}>
                                                <img src='img/services/ground.png' style={{color:'white'}}/>
                                            </div>
                                            <h6>Kids Playground</h6>
                                            <p>
                                                Relax and unwind while keeping an eye on your children from our designated parental supervision area.                                            </p>

                                        </div>

                                        <div className='col-lg-4 d-flex flex-column  justify-content-start' style={{gap:'10px'}}>
                                            <div className='rounded-circle icon-round d-flex justify-content-center align-items-center' style={{width:'54px',height:'54px'}}>
                                                <img src='img/services/glass.png' style={{color:'white'}}/>
                                            </div>
                                            <h6>Mini Bar</h6>
                                            <p>
                                                Mingle with fellow travelers and enjoy expertly crafted cocktails at trendy bars just a short stroll away.                                            </p>

                                        </div>

                                        <div className='col-lg-4 col-xl-4 d-flex flex-column  justify-content-start' style={{gap:'10px'}}>
                                            <div className='rounded-circle d-flex justify-content-center align-items-center icon-round' style={{width:'54px',height:'54px'}}>
                                                <img src='img/services/wifi.png' style={{color:'white'}}/>
                                            </div>
                                            <h6>Free Wifi
                                            </h6>
                                            <p>
                                                Use our free Wi-Fi to research local attractions, book tours, or plan your itinerary for exploring Unawatuna and Sri Lanka.

                                            </p>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </section>
            {/*end of  our services*/}

            {/*start of count*/}
            <section className='spad'>
                <div id='count-main' className='container-fluid' style={{backgroundColor:'#111111'}}>
                    <div className='container d-flex justify-content-center align-items-center h-100'>
                        <div className='row  d-flex justify-content-center'>
                            <div className='col-lg-9'>

                                <div className='row justify-content-center '>
                                    <div className='col-lg-8 col-md-6 col-sm-8 mt-4 d-flex justify-content-start align-items-center' >
                                        <h2 className='text-center text-white'>Doing the right thing
                                            at the right time.</h2>
                                    </div>
                                    <div className='col-lg-12 w-100 mt-4 d-flex justify-content-center align-items-center' >
                                        <div className='row justify-content-center w-100 count-last' >
                                           <div className='col-lg-3 col-md-3 col-sm-6 d-flex flex-column justify-content-center align-items-center' style={{gap:'5px'}}>
                                               <h6 style={{color:'#b8a680'}}>
                                                   Suit Rooms
                                               </h6>
                                               <h2  onMouseEnter={() => setHover1(true)}
                                                    onMouseLeave={() => setHover1(false)} className='text-white fw-bold'>{hover1 ? <FaHotel className='text-white' size={50} /> : '3'}</h2>                                           </div>
                                            <div className='col-lg-3 col-md-3 col-sm-6 d-flex flex-column justify-content-center align-items-center' style={{gap:'5px'}}>
                                                <h6 style={{color:'#b8a680'}}>
                                                    Regular Rooms
                                                </h6>
                                                <h2  onMouseEnter={() => setHover2(true)}
                                                     onMouseLeave={() => setHover2(false)} className='text-white fw-bold'>{hover2 ? <RiHotelFill className='text-white' size={50} /> : '5'}</h2>                                            </div>
                                            <div className='col-lg-3 col-md-3 col-sm-6 d-flex flex-column justify-content-center align-items-center' style={{gap:'5px'}}>
                                                <h6 style={{color:'#b8a680'}}>
                                                    Staffs
                                                </h6>
                                                <h2  onMouseEnter={() => setHover3(true)}
                                                     onMouseLeave={() => setHover3(false)} className='text-white fw-bold'>{hover3 ? <FaUser className='text-white' size={50} /> : '10+'}</h2>                                            </div>
                                            <div className='col-lg-3 col-md-3 col-sm-6 d-flex flex-column justify-content-center align-items-center' style={{gap:'5px'}}>
                                                <h6 style={{color:'#b8a680'}}>
                                                    Services

                                                </h6>
                                                <h2  onMouseEnter={() => setHover(true)}
                                                     onMouseLeave={() => setHover(false)} className='text-white fw-bold'>{hover ? <FaServicestack className='text-white' size={50} /> : '8'}</h2>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </section>
            {/*end of count*/}
            {/*start of  our services*/}
            <section className='' style={{paddingBottom:'80px'}}>
                <div className='container'>
                    <div className='row  d-flex justify-content-center'>
                        <div className='col-lg-10 justify-content-center'>

                                    <div className='row faci-last-div'>
                                        <div className='col-lg-3 col-md-6 d-flex flex-column  justify-content-center align-items-center' style={{gap:'10px'}}>
                                            <div className='rounded-circle  d-flex  justify-content-center align-items-center' style={{width:'54px',height:'54px'}}>
                                                <img src='img/services/wallet.png' style={{color:'white'}}/>
                                            </div>
                                            <h5>Cash Payment</h5>

                                        </div>
                                        <div className='col-lg-3 col-md-6 d-flex flex-column  justify-content-center align-items-center' style={{gap:'10px'}}>
                                            <div className='rounded-circle d-flex justify-content-center  align-items-center' style={{width:'54px',height:'54px'}}>
                                                <img src='img/services/skype.png' style={{color:'white'}}/>
                                            </div>
                                            <h5>Booking Request</h5>

                                        </div>
                                        <div className='col-lg-3 col-md-6 faci-last-div-sub d-flex flex-column  justify-content-center align-items-center' style={{gap:'10px'}}>
                                            <div className='rounded-circle d-flex justify-content-center  align-items-center' style={{width:'54px',height:'54px'}}>
                                                <img src='img/services/dollar.png' style={{color:'white'}}/>
                                            </div>
                                            <h5>Payment Gateway</h5>

                                        </div>
                                        <div className='col-lg-3 col-md-6 faci-last-div-sub d-flex flex-column  justify-content-center align-items-center' style={{gap:'10px'}}>
                                            <div className='rounded-circle d-flex justify-content-center  align-items-center' style={{width:'54px',height:'54px'}}>
                                                <img src='img/services/cards.png' style={{color:'white'}}/>
                                            </div>
                                            <h5>Card Payment
                                            </h5>

                                        </div>
                                    </div>



                            </div>
                        </div>


                    </div>

            </section>
            {/*end of  our services*/}

        </>
    )
}

export default Facilities