import React from "react";



function Accomadation() {
    return (

        <>

            <div className="container-fluid header-bg py-5 mb-5 wow fadeIn header_bg-about1" data-wow-delay="0.1s">
                <div className="container py-5 d-flex justify-content-start" style={{flexDirection: 'column'}}>
                    <h3 className="display-4 text-white mb-3 animated slideInDown"
                        style={{fontSize: '40px'}}>Accommodation </h3>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                            <li className="breadcrumb-item text-primary active" aria-current="page">Accommodation</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <section className=''>
                <div className='container'>
                    <div className='row  d-flex justify-content-center'>
                        <div className='col-lg-9'>
                            <div className='row p-3' style={{gap: '35px'}}>
                                <div className='col-lg-12'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12 position-relative'>
                                            <h2 className='room-header'>
                                                Duluxe Family Room
                                            </h2>
                                            <p style={{marginTop: '5px'}}>
                                                Deluxe family rooms with 2 bed, are modern decorated and offering
                                                comfortable hospitality to a family with with 2 children, or a company
                                                of 4 adults
                                            </p>
                                            <div className=' row room-detail'>
                                                <div className='col-lg-2 col-md-2  d-flex '
                                                     style={{flexDirection: 'column', gap: '10px'}}>
                                                    <p className='mb-0 ' style={{fontSize: '13px'}}>
                                                        Room Size</p>
                                                    <h6> 47 m
                                                    </h6>
                                                </div>
                                                <div className='col-lg-3 col-md-3 d-flex '
                                                     style={{flexDirection: 'column', gap: '10px'}}>
                                                    <p className='mb-0 ' style={{fontSize: '13px'}}>Num of bed</p>
                                                    <h6> 2 Double beds

                                                    </h6>
                                                </div>
                                                <div className='col-lg-3 col-md-3 d-flex '
                                                     style={{flexDirection: 'column', gap: '10px'}}>
                                                    <p className='mb-0 ' style={{fontSize: '13px'}}>
                                                        Person</p>
                                                    <h6> 02 person

                                                    </h6>
                                                </div>
                                                <div className='col-lg-4 col-md-4 d-flex '
                                                     style={{flexDirection: 'column', gap: '10px'}}>
                                                    <p className='mb-0 ' style={{fontSize: '13px'}}>View</p>
                                                    <div className='d-flex room-detail-div'>
                                                        <h6> Beach view
                                                        </h6>
                                                        <h6 className='fw-bold text-dark'>
                                                            LKR 22500
                                                        </h6>
                                                    </div>

                                                </div>
                                            </div>
                                            <a href="/booknow" className='room-btn '>Book now</a>

                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <img src='img/room/room1.png' width='100%' height='100%'/>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    <div className='row'>

                                        <div className='col-lg-6 col-md-12 position-relative'>
                                            <h2 className='room-header'>
                                                Deluxe Double Room with Balcony and Sea View
                                            </h2>
                                            <p style={{marginTop: '5px'}}>
                                                The Specious double room features are conditioning, a tea and coffee
                                                maker, a terrace with sea views as well as a private bathroom boasting a
                                                bath.The unit has ! bed
                                            </p>
                                            <div className=' row room-detail'>
                                                <div className='col-lg-2 col-md-2  d-flex '
                                                     style={{flexDirection: 'column', gap: '10px'}}>
                                                    <p className='mb-0 ' style={{fontSize: '13px'}}>
                                                        Room Size</p>
                                                    <h6> 47 m
                                                    </h6>
                                                </div>
                                                <div className='col-lg-3 col-md-3 d-flex '
                                                     style={{flexDirection: 'column', gap: '10px'}}>
                                                    <p className='mb-0 ' style={{fontSize: '13px'}}>Num of bed</p>
                                                    <h6> 2 Double beds

                                                    </h6>
                                                </div>
                                                <div className='col-lg-3 col-md-3 d-flex '
                                                     style={{flexDirection: 'column', gap: '10px'}}>
                                                    <p className='mb-0 ' style={{fontSize: '13px'}}>
                                                        Person</p>
                                                    <h6> 02 person

                                                    </h6>
                                                </div>
                                                <div className='col-lg-4 col-md-4 d-flex '
                                                     style={{flexDirection: 'column', gap: '10px'}}>
                                                    <p className='mb-0 ' style={{fontSize: '13px'}}>View</p>
                                                    <div className='d-flex room-detail-div'>
                                                        <h6> Beach view
                                                        </h6>
                                                        <h6 className='fw-bold text-dark'>
                                                            LKR 22500
                                                        </h6>
                                                    </div>

                                                </div>
                                            </div>
                                            <a href="/booknow" className='room-btn '>Book now</a>

                                        </div>
                                        <div className='col-lg-6'>
                                            <img src='img/room/room2.png'/>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12 position-relative'>
                                            <h2 className='room-header'>
                                                King Room With Sea View </h2>
                                            <p style={{marginTop: '5px'}}>
                                                Superior sea view king bed room, are modern decorated and offering
                                                comfortable hospitality to a family with 2 children, or a company of 4
                                                adults

                                            </p>
                                            <div className=' row room-detail'>
                                                <div className='col-lg-2 col-md-2  d-flex '
                                                     style={{flexDirection: 'column', gap: '10px'}}>
                                                    <p className='mb-0 ' style={{fontSize: '13px'}}>
                                                        Room Size</p>
                                                    <h6> 47 m
                                                    </h6>
                                                </div>
                                                <div className='col-lg-3 col-md-3 d-flex '
                                                     style={{flexDirection: 'column', gap: '10px'}}>
                                                    <p className='mb-0 ' style={{fontSize: '13px'}}>Num of bed</p>
                                                    <h6> 2 Double beds

                                                    </h6>
                                                </div>
                                                <div className='col-lg-3 col-md-3 d-flex '
                                                     style={{flexDirection: 'column', gap: '10px'}}>
                                                    <p className='mb-0 ' style={{fontSize: '13px'}}>
                                                        Person</p>
                                                    <h6> 02 person

                                                    </h6>
                                                </div>
                                                <div className='col-lg-4 col-md-4 d-flex '
                                                     style={{flexDirection: 'column', gap: '10px'}}>
                                                    <p className='mb-0 ' style={{fontSize: '13px'}}>View</p>
                                                    <div className='d-flex room-detail-div'>
                                                        <h6> Beach view
                                                        </h6>
                                                        <h6 className='fw-bold text-dark'>
                                                            LKR 30000
                                                        </h6>
                                                    </div>

                                                </div>
                                            </div>
                                            <a href="/booknow" className='room-btn '>Book now</a>

                                        </div>
                                        <div className='col-lg-6'>
                                            <img src='img/room/room3.jpg'/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>

        </>
    );
}

export default Accomadation;