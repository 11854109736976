import React from "react";

function WhyImportantRumassala() {
    return (
        <>
            <div className="container-fluid header-bg py-5 mb-5 wow fadeIn header_bg-whyImportant"
                 data-wow-delay="0.1s">
                <div className="container py-5 d-flex justify-content-start" style={{flexDirection: 'column'}}>
                    <h3 className="display-4 text-white mb-3 animated slideInDown"
                        style={{fontSize: '40px'}}>Why Important Rumassala

                    </h3>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                            <li className="breadcrumb-item"><a className="text-white" href="/">News</a></li>
                            <li className="breadcrumb-item text-primary active" aria-current="page">Why Important
                                Rumassala
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className='spad3'>
                <div className='container'>
                    <div className='row  d-flex justify-content-center'>
                        <div className='col-lg-10 col-xl-9 col-md-12' style={{backgroundColor: '#e5e4e2'}}>
                            <div className='row   justify-content-center'>
                                <div className='col-lg-8 col-md-12 col-sm-8 d-flex justify-content-center py-5 ' style={{flexDirection: 'column', gap: '5px',}}>
                                    <h3>Why Important Rumassala</h3>
                                    <img src='img/blog/junglebeach.jpg' width='100%'/>
                                    <div className='d-flex flex-column mt-2' >

                                        <p>
                                            Unawatuna, a jewel on Sri Lanka's south coast, boasts more than just
                                            stunning beaches and vibrant coral reefs. Nestled beside it is the legendary
                                            Rumassala Hill, adding a layer of cultural and historical significance to
                                            the area. According to the Ramayana, the epic Indian tale, Hanuman, the
                                            monkey god, ripped off a piece of the Himalayas to heal Lakshmana, Rama's
                                            brother. This mythical fragment is believed to have formed Rumassala,
                                            explaining the presence of unique medicinal herbs still found there today.
                                            The hill itself is a sacred site, adorned with a Buddhist temple and a
                                            statue dedicated to Hanuman. Unawatuna and Rumassala offer a captivating
                                            blend of relaxation and exploration. Unwind on the pristine shores, then
                                            delve into the rich heritage of Rumassala, creating a truly unforgettable
                                            experience.
                                        </p>
                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </section>

        </>
    )
}

export default WhyImportantRumassala