import Slider from "react-slick";
import React from "react";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';



const resturant = [
    {
        image: 'img/services/spa1.jpg',
        title: 'Wellness Spa',
        description: 'Find solace in our beautifully designed spa sanctuary. Lush greenery, calming water features, and soothing music create the perfect environment for relaxation.'
    },
    {
        image: 'img/services/spa2.jpg',
        title: 'Wellness Spa',
        description: 'Find solace in our beautifully designed spa sanctuary. Lush greenery, calming water features, and soothing music create the perfect environment for relaxation.'

    },
    {
        image: 'img/services/spa3.jpg',
        title: 'Wellness Spa',
        description: 'Find solace in our beautifully designed spa sanctuary. Lush greenery, calming water features, and soothing music create the perfect environment for relaxation.'

    }
]

function FacilitySlidesSec() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return(
        <div className='res-main-dive' >
            <Slider {...settings}>

                {resturant.map((item, index) => (
                    <div className='d-flex res-div1 ' >

                        <div className='position-relative '>
                            <div className='facilities-detail1   '>
                                <h2 className='room-header'>
                                    {item.title}
                                </h2>
                                <p style={{marginTop: '5px'}}>
                                    {item.description}
                                </p>
                                <a href="#" className='facilities-btn '>Read More</a>
                            </div>

                        </div>
                        <div className=''>
                            <img src={item.image} className='res-img' width='400px' height='400px' />
                        </div>

                    </div>
                ))}
            </Slider>
        </div>
    )
}
export default FacilitySlidesSec;