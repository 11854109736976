import React, { useState, useEffect } from "react";
import logo3 from './logo3.png';
// import logo from './logo.png';
import { TbBrandBooking } from "react-icons/tb";


const Navbar = () => {
    const [isSticky, setIsSticky] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsSticky(window.scrollY > 300);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <>
            {/*Topbar Start */}

            <div className="top-nav p-2 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <ul className="tn-left">
                                <li><i className="fa fa-phone"></i> (+94) 75 610 6173</li>
                                <li><i className="fa fa-envelope"></i> info@parkparadiserumassala.com</li>
                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <div className="tn-right">
                                <div className="top-social">
                                    <a href="https://www.facebook.com/share/1JWhNwMNwcpshrEa/?mibextid=LQQJ4d" target="_blank"><i className="fa fa-facebook"></i></a>
                                    <a href="https://www.booking.com/hotel/lk/park-paradise-rumassala.en-gb.html" target="_blank"><TbBrandBooking size={20}></TbBrandBooking></a>
                                    <a href="#"><i className="fa fa-tripadvisor"></i></a>
                                    <a href="https://www.instagram.com/parkparadise_?igsh=MTV2NzZ1YmYzb2VreQ==" target="_blank"><i className="fa fa-instagram"></i></a>
                                </div>
                                <a href="/booknow" className="bk-btn">Book Now</a>
                                {/* <div className="language-option">
                                    <img src="img/flag.jpg" alt=""></img>
                                    <span>EN <i className="fa fa-angle-down"></i></span>
                                    <div className="flag-dropdown">
                                        <ul>
                                            <li><a href="#">Zi</a></li>
                                            <li><a href="#">Fr</a></li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*Topbar End */}

            <div className={`navbar-container ${isSticky ? 'sticky' : ''}`}>
                <nav className="navbar">
                    {/* <a to="/" className="navbar-brand">
                        <img src="img/Logo3.png" alt="logo" className="navbar-logo"/>
                    </a> */}
                    <a href="/"><img src={logo3} style={{ width: '70px', height: '70px' }} alt='Logo'></img></a>
                    <button className="navbar-toggler" type="button" onClick={toggleMenu}>
                        <span className="navbar-toggler-icon"></span>
                        <span className="navbar-toggler-icon"></span>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`navbar-menu ${isMenuOpen ? 'open' : ''}`} id="navbarMenu">
                        <a href="/" className="nav-item" onClick={() => setIsMenuOpen(false)}>Home</a>
                        <a href="/about" className="nav-item" onClick={() => setIsMenuOpen(false)}>About Us</a>
                        <a href="/rooms" className="nav-item" onClick={() => setIsMenuOpen(false)}>Accommodation</a>
                        <a href="/facilities" className="nav-item"
                            onClick={() => setIsMenuOpen(false)}>Facilities</a>

                        <div className="nav-item dropdown">
                            <span className="dropdown-toggle" onClick={toggleDropdown}>Pages</span>
                            <div className={`dropdown-menu ${isDropdownOpen ? 'open' : ''}`}>
                                <a href="/blog" className="dropdown-item" onClick={() => setIsMenuOpen(false)}>Blog</a>
                                <a href="/place" className="dropdown-item" onClick={() => setIsMenuOpen(false)}>Place around
                                    hotel</a>
                            </div>
                        </div>
                        <a href="/contact" className="nav-item" onClick={() => setIsMenuOpen(false)}>Contact</a>
                    </div>
                </nav>
            </div>
        </>
    );
};

export default Navbar;