import React from "react";
import {FaLongArrowAltRight} from "react-icons/fa";

function Blog() {
    return(
        <>
            <div className="container-fluid header-bg py-5 mb-5 wow fadeIn header_bg-about3" data-wow-delay="0.1s">
                <div className="container py-5 d-flex justify-content-start" style={{flexDirection: 'column'}}>
                    <h3 className="display-4 text-white mb-3 animated slideInDown" style={{fontSize: '40px'}}>Blog
                        Us</h3>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                            <li className="breadcrumb-item text-primary active" aria-current="page">Blog</li>
                        </ol>
                    </nav>
                </div>

            </div>
            <section className='spad3'>
                <div className='container'>

                    <div className='row justify-content-center news-main'>
                        <div className='col-lg-9 '>
                            <div className='row justify-content-center '>
                                <div className='news-img-div1 col-lg-5  mb-3  d-flex justify-content-center'
                                     style={{border: '2px solid #b8a680', height: '250px', width: '100%'}}>
                                    <div className='content d-flex justify-content-center'
                                         style={{flexDirection: 'column'}}>
                                        <p style={{color: '#b8a680'}}>May 1,2024</p>
                                        <h4>Top things to do in Galle, Sri Lanka</h4>
                                        <div className='d-flex align-items-center justify-content-end mt-5'
                                             style={{gap: '10px'}}>
                                            <a href='/toDo' style={{fontSize: '13px', color: '#b8a680'}}>Read More</a>
                                            <FaLongArrowAltRight/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-5 news-img-div2  mb-3 d-flex justify-content-center'
                                     style={{border: '2px solid #b8a680', height: '250px', width: '100%'}}>
                                    <div className='content d-flex justify-content-center'
                                         style={{flexDirection: 'column'}}>
                                        <p style={{color: '#b8a680'}}>March 25,2024</p>
                                        <h4>Why Rumassala is important</h4>
                                        <div className='d-flex align-items-center justify-content-end mt-5'
                                             style={{gap: '10px'}}>
                                            <a href='/whtim' style={{fontSize: '13px', color: '#b8a680'}}>Read More</a>
                                            <FaLongArrowAltRight/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-5 mb-3 news-img-div3 d-flex justify-content-center'
                                     style={{border: '2px solid #b8a680', height: '250px', width: '100%'}}>
                                    <div className='content d-flex justify-content-center'
                                         style={{flexDirection: 'column'}}>
                                        <p style={{color: '#b8a680'}}>october 1,2023</p>
                                        <h4>When is the best time to visit Sri Lanka</h4>
                                        <div className='d-flex align-items-center justify-content-end mt-5'
                                             style={{gap: '10px'}}>
                                            <a href='/bestTime' style={{fontSize: '13px', color: '#b8a680'}}>Read More</a>
                                            <FaLongArrowAltRight/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-5 news-img-div4 mb-3 d-flex justify-content-center'
                                     style={{border: '2px solid #b8a680', height: '250px', width: '100%'}}>
                                    <div className='content d-flex justify-content-center'
                                         style={{flexDirection: 'column'}}>
                                        <p style={{color: '#b8a680'}}>May 1,2023</p>
                                        <h4>Rumassala Mountain, Sri Lanka</h4>
                                        <div className='d-flex align-items-center justify-content-end mt-5'
                                             style={{gap: '10px'}}>
                                            <a href='/mount' style={{fontSize: '13px', color: '#b8a680'}}>Read More</a>
                                            <FaLongArrowAltRight/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>


            </section>




        </>
    )
}
export default Blog