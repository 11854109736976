import Slider from "react-slick";
import React from "react";
import styled from "styled-components";

const SliderContainer = styled.div`
    .slick-slide > div {
        margin: 0px 0px;
    }
  .slick-dots {
    bottom: auto;
    top: -30px; /* Adjust this value to control the distance from the top */
    border: 1px sandybrown;
  }

  .slick-dots li button:before {
    font-size: 12px; 
  }

  .slick-dots li.slick-active button:before {
    color: #b8a680; 
  }
`;

function PlaceArroundSlide() {

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const breakfastItems = [
        {
            image: 'img/placeArround/coctail.jpg',
            title: 'Bar & Restaurant',
            description: 'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in,'
        },
        {
            image: 'img/placeArround/sli1.png',
            title: 'Shopping Mall',
            description: 'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in,'
        },
        {
            image: 'img/placeArround/sli2.png',
            title: 'Fun & Activities',
            description: 'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in,'
        },
        {
            image: 'img/placeArround/sli3.png',
            title: 'Tourist Places',
            description: 'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in,'
        },
        {
            image: 'img/placeArround/slid5.png',
            title: 'Health Care',
            description: 'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in,'
        },
        {
            image: 'img/placeArround/sli1.png',
            title: 'Shopping Mall',
            description: 'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in,'
        },
        {
            image: 'img/placeArround/sli2.png',
            title: 'Fun & Activities',
            description: 'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in,'
        }
    ];
    return(
        <SliderContainer>
            <Slider {...settings}>
                {breakfastItems.map((item, index) => (
                    <div key={index} className='place-menu' style={{ padding: '10px' }}>

                        <div className='d-flex justify-content-center align-items-center' style={{ backgroundColor: '#C6C3C3',height:'50px',width:'100%', textAlign: 'center' }}>
                            <h5 className='fw-bold'>{item.title}</h5>
                        </div>
                        <div style={{ width: '100%',height:'400px' }}>
                            <img width='100%' height='100%' src={item.image} alt={item.title} />
                        </div>
                    </div>
                ))}
            </Slider>
        </SliderContainer>
    )
}
export default PlaceArroundSlide
