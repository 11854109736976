import React from "react";
import {FaWifi} from "react-icons/fa";
import {RiComputerLine} from "react-icons/ri";
import {IoSnowSharp} from "react-icons/io5";
import {FaBath, FaPerson} from "react-icons/fa6";


function Booknow() {
    return (
        <>
            <div className="container-fluid header-bg py-5 mb-5 wow fadeIn header_bg-bookNow" data-wow-delay="0.1s">
                <div className="container py-5 d-flex justify-content-start" style={{flexDirection: 'column'}}>
                    <h3 className="display-4 text-white mb-3 animated slideInDown" style={{fontSize: '40px'}}>Book
                        now</h3>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                            <li className="breadcrumb-item text-primary active" aria-current="page">Book now</li>
                        </ol>
                    </nav>
                </div>

            </div>
            <section className='spad3'>
                <div className='container'>
                    <div className='row  d-flex justify-content-center'>
                        <div className='col-lg-10 col-xl-9 col-md-12'>
                            <div className='row book-main   justify-content-center' style={{gap:'20px'}}>
                                <div className='col-lg-10 col-xl-9 col-md-12 col-sm-8  justify-content-center  '>
                                    <div className='row' style={{border: '1px solid gray'}}>
                                        <div className='col-lg-4 col-md-4 p-0'>
                                            <img src='img/room/room2.png'/>
                                        </div>
                                        <div className='col-lg-5 col-md-5 d-flex flex-column' style={{gap: '15px'}}>
                                            <a className='mt-3'> Deluxe Double Room </a>
                                            <div className='d-flex' style={{gap: '5px'}}>
                                                <FaWifi/>
                                                <RiComputerLine/>
                                                <IoSnowSharp/>
                                                <FaBath/>
                                            </div>
                                        </div>

                                        <div className='col-lg-3 col-md-3 book-last-div  justify-content-center align-items-center w-100 d-flex flex-column' style={{ gap: '10px'}}>
                                            <div className='d-flex mt-3'>
                                                <FaPerson style={{color: '#3286b8',}} size='25'/>
                                                <FaPerson style={{color: '#3286b8'}} size='25'/>
                                            </div>
                                            <h4>
                                                $ 61
                                            </h4>
                                            <p>per night</p>
                                            <a href="/doubleRoom" className='details-btn'>Details</a>

                                        </div>
                                    </div>

                                </div>
                                <div className='col-lg-10 col-xl-9 col-md-12 col-sm-8  justify-content-center  '>

                                    <div className='row' style={{border: '1px solid gray'}}>
                                        <div className='col-lg-4 col-md-4 p-0'>
                                            <img src='img/room/room3.jpg'/>
                                        </div>
                                        <div className='col-lg-5 col-md-5 d-flex flex-column' style={{gap: '15px'}}>
                                            <a className='mt-3'> Deluxe Family Room </a>
                                            <div className='d-flex' style={{gap: '5px'}}>
                                                <FaWifi/>
                                                <RiComputerLine/>
                                                <IoSnowSharp/>
                                                <FaBath/>
                                            </div>
                                        </div>

                                        <div
                                            className='col-lg-3  col-md-3 book-last-div justify-content-center align-items-center w-100 d-flex flex-column'
                                            style={{ gap: '10px'}}>
                                            <div className='d-flex mt-3'>
                                                <FaPerson style={{color: '#3286b8',}} size='25'/>
                                                <FaPerson style={{color: '#3286b8'}} size='25'/>
                                                <FaPerson style={{color: '#3286b8'}} size='25'/>
                                                <FaPerson style={{color: '#3286b8'}} size='25'/>
                                            </div>
                                            <h4>
                                                $ 76
                                            </h4>
                                            <p>per night</p>
                                            <a href="/familyRoom" className='details-btn'>Details</a>

                                        </div>


                                    </div>

                                </div>
                                <div className='col-lg-10 col-xl-9 col-md-12 col-sm-8  justify-content-center  '>
                                    <div className='row' style={{border: '1px solid gray'}}>
                                        <div className='col-lg-4 col-md-4 p-0'>
                                            <img src='img/room/room1.png'/>
                                        </div>
                                        <div className='col-lg-5 col-md-5 d-flex flex-column' style={{gap: '15px'}}>
                                            <a className='mt-3'>  King Room </a>
                                            <div className='d-flex' style={{gap: '5px'}}>
                                                <FaWifi/>
                                                <RiComputerLine/>
                                                <IoSnowSharp/>
                                                <FaBath/>
                                            </div>
                                        </div>

                                        <div
                                            className='col-lg-3 col-md-3 book-last-div justify-content-center align-items-center w-100 d-flex flex-column'
                                            style={{ gap: '10px'}}>
                                            <div className='d-flex mt-3'>
                                                <FaPerson style={{color: '#3286b8',}} size='25'/>
                                                <FaPerson style={{color: '#3286b8'}} size='25'/>
                                            </div>
                                            <h4>
                                                $ 91
                                            </h4>
                                            <p>per night</p>
                                            <a href="/kingRoom" className='details-btn'>Details</a>

                                        </div>


                                    </div>


                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>

        </>
    )
}

export default Booknow