import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./Pages/Home";
import Footer from "./Component/Footer";
import Navbar from "./Component/Navbar";
import AboutUs from "./Pages/AboutUs";
import Accomadation from "./Pages/Accomadation";
import Facilities from "./Pages/Facilities";
import Blog from "./Pages/Blog";
import PlaceArround from "./Pages/PlaceArround";
import Contact from "./Pages/Contact";
import ThingsToDo from "./Pages/BlogArticle/ThingsToDo";
import WhyImportantRumassala from "./Pages/BlogArticle/WhyImportantRumassala";
import BestTimeVisit from "./Pages/BlogArticle/BestTimeVisit";
import ImportantRumassala from "./Pages/BlogArticle/RumassalaMountain";
import RumassalaMountain from "./Pages/BlogArticle/RumassalaMountain";
import Booknow from "./Pages/Booknow";
import BookingDetails from "./Pages/BookingDoubleRoom";
import BookingDoubleRoom from "./Pages/BookingDoubleRoom";
import BookingFamilyRoom from "./Pages/BookingFamilyRoom";
import BookingKingRoom from "./Pages/BookingKingRoom";

function App() {
  return (

      <div className="App">
          <BrowserRouter>
              <Navbar/>
              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<AboutUs />} />
                  <Route path="/rooms" element={<Accomadation />} />
                  <Route path="/facilities" element={<Facilities />} />
                  <Route path="/blog" element={<Blog />} />
                  <Route path="/place" element={<PlaceArround />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/toDo" element={<ThingsToDo />} />
                  <Route path="/whtim" element={<WhyImportantRumassala />} />
                  <Route path="/bestTime" element={<BestTimeVisit />} />
                  <Route path="/mount" element={<RumassalaMountain />} />
                  <Route path="/booknow" element={<Booknow />} />
                  <Route path="/doubleRoom" element={<BookingDoubleRoom />} />
                  <Route path="/familyRoom" element={<BookingFamilyRoom />} />
                  <Route path="/kingRoom" element={<BookingKingRoom />} />







              </Routes>
              <Footer/>
          </BrowserRouter>
      </div>
  );
}

export default App;
