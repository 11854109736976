import React from "react";
import Slides from "./slides/slide";
import PlaceArroundSlide from "./slides/PlaceArroundSlide";
import { FaLongArrowAltRight } from "react-icons/fa";

function PlaceArround() {
    return (
        <>
            <div className="container-fluid header-bg py-5 mb-5 wow fadeIn header_bg-about4" data-wow-delay="0.1s">
                <div className="container py-5 d-flex justify-content-start" style={{ flexDirection: 'column' }}>
                    <h3 className="display-4 text-white mb-3 animated slideInDown" style={{ fontSize: '40px' }}>Place Around Hotel</h3>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                            <li className="breadcrumb-item text-primary active" aria-current="page">Place Around Hotel</li>
                        </ol>
                    </nav>
                </div>
            </div>

            {/*start of  our services*/}
            <section className='spad'>
                <div className='container'>
                    <div className='row  d-flex justify-content-center'>
                        <div className='col-lg-9 col-md-12'>
                            <div className='row place-around-div  justify-content-center' >
                                <div className='col-lg-5 col-md-6 col-sm-8 d-flex justify-content-start align-items-center' style={{
                                    flexDirection: 'column',
                                    gap: '5px',

                                }}>

                                    <div className='w-100 d-flex justify-content-center' style={{ flexDirection: 'column', gap: '10px' }}>
                                        <h5>Step into Park paradise, your luxurious beachfront sanctuary where relaxation and adventure seamlessly blend.
                                        </h5>
                                        <p style={{ fontSize: '15px', lineHeight: '20px' }}>
                                            Explore the historic ramparts of Galle Fort, a UNESCO World Heritage Site. Immerse yourself in the untamed beauty of a nearby jungle beach. Discover hidden coves and vibrant coral reefs teeming with marine life. The endless expanse of the Indian Ocean beckons, promising unforgettable adventures.


                                        </p>


                                    </div>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <img src='img/about/about2.png' />
                                    </div>
                                </div>
                                <div className='ab-div col-lg-5 col-md-6 col-sm-8 d-flex justify-content-start ' style={{
                                    height: '', flexDirection: 'column', gap: '5px',
                                }}>
                                    <div className='w-100 d-flex align-items-end '>
                                        <h2 className='fw-bold text-right'>Discover Unlimited Beauty
                                        </h2>

                                    </div>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <img src='img/placeArround/place-arround2.png' />
                                    </div>
                                    <h5 className='mt-2'>Make your trip fun and free</h5>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </section>
            {/*end of  our services*/}

            <section>
                <div id='place-arround-div2' className='container-fluid'>
                    <div className='row  d-flex justify-content-center h-100'>
                        <div className='col-lg-9 col-md-12'>
                            <div className='row h-100  justify-content-center' >
                                <div className='col-lg-5 col-md-6 col-sm-8 d-flex justify-content-center ' style={{
                                    flexDirection: 'column',
                                    gap: '15px',

                                }}>
                                    <h2>Popular Places</h2>
                                    <p className='fw-bold text-dark'>
                                        Book your stay with us today and unlock the magic of Sri Lanka's southern coast. Our hotel provides the perfect launching point for your unforgettable island adventure!
                                    </p>
                                </div>
                                <div className=' col-lg-5 col-md-6 col-sm-8 d-flex justify-content-center ' style={{
                                    height: '', flexDirection: 'column', gap: '5px',
                                }}>
                                    <p style={{ fontSize: '15px' }}>Our prime location near these popular destinations allows you to easily explore the southern coast without spending hours on the road</p>
                                </div>

                            </div>
                        </div>


                    </div>

                </div>
                <div className='container-fluid ' style={{ backgroundColor: '#e5e4e2' }}>
                    <PlaceArroundSlide />
                </div>
            </section>

            <section className='spad'>
                <div className='container'>
                    <div className='row  d-flex justify-content-center'>
                        <div className='col-lg-12 col-xl-9 col-md-12'>
                            <div className='row   justify-content-center' style={{ gap: '3px' }} >

                                <div className=' col-lg-5 col-xl-5 p-0 col-md-5 col-sm-8 d-flex justify-content-start ' style={{
                                    height: '', flexDirection: 'column', gap: '40px',
                                }}>
                                    <div className='w-75 d-flex  '>
                                        <h2 className='fw-bold '>
                                            Sri Lanka Tours Private & Tailored
                                        </h2>

                                    </div>
                                    <div className='w-50 d-flex  align-items-center'>
                                        <img src='img/placeArround/place1.jpg' />
                                        <img src='img/placeArround/place2.jpg' />
                                    </div>                                </div>
                                <div className='col-lg-6 col-xl-6 p-0 col-md-6 col-sm-8 d-flex justify-content-start align-items-center' style={{
                                    flexDirection: 'column',

                                }}>

                                    <div className='w-100 d-flex justify-content-center py-4 px-5 ' style={{ flexDirection: 'column', gap: '20px', backgroundColor: '#e5e4e2', padding: '20px' }}>
                                        <h5>Tour with Park Paradise
                                        </h5>
                                        <p style={{ fontSize: '15px', lineHeight: '20px' }}>
                                            Sri Lanka Tour Plan Signature Tour Package is premium tour plan with Yoga, Meditation, Sri Lankan Style Ayurveda, Trekking, Surfing and Cycling and many more.



                                        </p>


                                    </div>
                                    <div className='w-50 d-flex justify-content-center'>
                                        <img src='img/placeArround/place4.jpg' />
                                        <img src='img/placeArround/place3.jpg' />
                                    </div>
                                    <div className='w-100 ' style={{ height: '100px', backgroundColor: 'black' }}>
                                        <div className=''>
                                            <p className='p-3 d-flex align-items-center' style={{ color: '#b8a680', gap: '5px' }}>
                                                Read more about tour
                                                <FaLongArrowAltRight />
                                            </p>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>

            </section>
        </>
    )
}
export default PlaceArround