import { TbBrandBooking } from "react-icons/tb";

function Footer() {
    return (
        <div>
            {/* Footer Section Begin*/}
            <footer className="footer-section">
                <div className="container">
                    <div className="footer-text">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="ft-about">
                                    <div className="logo">
                                        <h2 className='text-white'>
{/*
                                            <img src="img/footer-logo.png" alt=""></img>
*/}
                                            Park Paradise
                                        </h2>
                                    </div>
                                    <p>Welcome to our mind-blowingly quaint <br />accommodation and enjoy our charm</p>
                                    <div className="fa-social">
                                        <a href="https://www.facebook.com/share/1JWhNwMNwcpshrEa/?mibextid=LQQJ4d" target="_blank"><i className="fa fa-facebook"></i></a>
                                        <a href="https://www.booking.com/hotel/lk/park-paradise-rumassala.en-gb.html" target="_blank"><TbBrandBooking size={22} className="fa fa-twitter"></TbBrandBooking></a>
                                        <a href="#"><i className="fa fa-tripadvisor"></i></a>
                                        <a href="https://www.instagram.com/parkparadise_?igsh=MTV2NzZ1YmYzb2VreQ==" target="_blank"><i className="fa fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 offset-lg-1">
                                <div className="ft-contact">
                                    <h6>Contact Us</h6>
                                    <ul>
                                        <li>(+94) 75 610 6173</li>
                                        <li>info@parkparadise@gmail.com</li>
                                        <li>Yaddehimulla, Rumassala, Unawatuna,<br/> Sri Lanka</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 offset-lg-1">
                                <div className="ft-newslatter">
                                    <h6>New latest</h6>
                                    <p>Get the latest updates and offers.</p>
                                    <form action="#" className="fn-form">
                                        <input type="text" placeholder="Email"/>
                                        <button type="submit"><i className="fa fa-send"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-option">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <ul>
                                    <li><a href="/contact">Contact</a></li>
                                    <li><a href="#">Terms of use</a></li>
                                    <li><a href="#">Privacy</a></li>
                                    <li><a href="#">Environmental Policy</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-5">
                                <div className="co-text"><p>
                                    Copyright {new Date().getFullYear()}. All rights reserved | Park Paradise <i className="fa fa-heart" aria-hidden="true"></i> by <a href="https://loopyholdings.com/" target="_blank">Loopy Holdings</a>
                                </p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/*Footer Section End*/}
        </div>
    )
}
export default Footer;