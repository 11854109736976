import React from "react";

function AboutUs() {
    return (
        <div>
            <div className="container-fluid header-bg py-5 mb-5 wow fadeIn header_bg-about" data-wow-delay="0.1s">
                <div className="container py-5 d-flex justify-content-start" style={{ flexDirection: 'column' }}>
                    <h3 className="display-4 text-white mb-3 animated slideInDown" style={{ fontSize: '40px' }}>About
                        Us</h3>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                            <li className="breadcrumb-item text-primary active" aria-current="page">About Us</li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/*start of  our services*/}
            <section className=''>
                <div className='container'>
                    <div className='row  d-flex justify-content-center'>
                        <div className='col-lg-8'>
                            <div className='row p-5'>
                                <div className='col-lg-4 col-md-6 col-sm-8 d-flex justify-content-center align-items-center' style={{
                                    height: '200px',
                                    flexDirection: 'column',
                                    gap: '5px',
                                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px'
                                }}>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <img src='img/about/ico-img1.png' width='40px' height='40px' />
                                    </div>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <h5>24/7 availabilty</h5>

                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-sm-8 d-flex justify-content-center align-items-center' style={{
                                    height: '200px',
                                    flexDirection: 'column',
                                    gap: '5px',
                                    boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset'
                                }}>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <img src='img/about/phone.png' width='40px' height='40px' />
                                    </div>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <h5>affordable Prices
                                        </h5>

                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-sm-8 d-flex justify-content-center align-items-center' style={{
                                    height: '200px',
                                    flexDirection: 'column',
                                    gap: '5px',
                                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px'
                                }}>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <img src='img/about/bag.png' width='40px' height='40px' />
                                    </div>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <h5>Discount Coupons
                                        </h5>

                                    </div>
                                </div>

                                <div className='col-lg-4 col-md-6 col-sm-8 d-flex justify-content-center align-items-center' style={{
                                    height: '200px',
                                    flexDirection: 'column',
                                    gap: '5px',
                                    boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset'
                                }}>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <img src='img/about/tab.png' width='40px' height='40px' />
                                    </div>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <h5>Privacy Policies
                                        </h5>

                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-sm-8 d-flex justify-content-center align-items-center' style={{
                                    height: '200px',
                                    flexDirection: 'column',
                                    gap: '5px',
                                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px'
                                }}>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <img src='img/about/suitcase.png' width='40px' height='40px' />
                                    </div>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <h5>Quality Services</h5>

                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-sm-8 d-flex justify-content-center align-items-center' style={{
                                    height: '200px',
                                    flexDirection: 'column',
                                    gap: '5px',
                                    boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset'
                                }}>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <img src='img/about/car.png' width='40px' height='40px' />
                                    </div>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <h5>Easy to Reach</h5>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
            {/*end of  our services*/}

            {/*start of  our services*/}
            <section className='spad'>
                <div className='container'>
                    <div className='row  d-flex justify-content-center'>
                        <div className='col-lg-9'>
                            <div className='row '>
                                <div className='col-lg-5 col-md-6 col-sm-8 d-flex justify-content-start align-items-center' style={{
                                    flexDirection: 'column',
                                    gap: '5px',

                                }}>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <img src='img/about/about1.png' />
                                    </div>
                                    <div className='w-100 d-flex justify-content-center' style={{ flexDirection: 'column' }}>
                                        <p>Escape to Paradise at Park Paradise, Unawatuna.  come and enjoy with us.</p>
                                        <a href="/contact" className='about_btn'>call us</a>


                                    </div>
                                </div>
                                <div className='ab-div col-lg-7 col-md-6 col-sm-8 d-flex justify-content-center align-items-center' style={{
                                    height: '', flexDirection: 'column', gap: '5px',
                                }}>
                                    <div className='w-100 d-flex  '>
                                        <h4 className='fw-bold'>Finest Hotel in Sri Lanka</h4>

                                    </div>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <img src='img/about/about2.png' />
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </section>
            {/*end of  our services*/}

            {/*start of  our about*/}
            <section className=''>
                <div className='container'>
                    <div className='row  d-flex justify-content-center'>
                        <div className='col-lg-8'>
                            <div className='row '>
                                <div className='col-lg-12 col-md-12 col-sm-8 d-flex justify-content-start align-items-center' style={{ flexDirection: 'column', gap: '5px', }}>
                                    <div className='w-100 d-flex justify-content-center' style={{ flexDirection: 'column' }}>
                                        <h4 className='fw-bold text-center'>
                                            Park Paradise Rumassala Experience the uniquee beauty of Sri Lanka's south coast beaches
                                        </h4>
                                        <div className='px-5'>
                                            <p className='text-justify mt-4'>
                                                Park Paradise Rumassala is a unique and exceptional designed luxury hotel that is built in Rumassala  . Offering breathtaking views we invite you to an unforgettable stay in Park  Paradise.
                                            </p>
                                            <p className='text-justify'>
                                                This would be an ideal place to spend your holidays during your leisure time. Park Paradise Rumassala gives you a calm and quiet environment that touches your heart with the serenity of human life with real nature at its core. Blessed with an acre of nature, experience the sights and sounds of Beach and have your heart touched with the serenity and luxury that Sri Lanka has to offer.


                                            </p>
                                            <p className='text-justify'>
                                                Guests of the hotel can enjoy on-site features like family room, Wi-Fi in public areas, 24hr room service. Guests can choose from 3 rooms, all of which exude an atmosphere of total peace and harmony. Throughout the day you can enjoy the relaxing atmosphere of the garden, outdoor pool,  . Whatever your reason for visiting Galle , Park Paradise Rumassala Hotel is the perfect venue for an exhilarating and exciting break away from the hustle and bustle.
                                            </p>
                                        </div>


                                    </div>

                                </div>
                                <div className=' col-lg-12 col-md-12 col-sm-8  justify-content-center align-items-center' style={{ height: '', gap: '5px', }}>
                                    <div className='count-div row ' style={{ gap: '20px', justifyContent: 'center' }}>
                                        <div className='col-lg-3 col-md-3 col-sm-3 d-flex justify-content-center align-items-center ' style={{ border: '2px solid #dfa974', height: '80px', width: '100%', flexDirection: 'column', gap: '5px' }}>
                                            <h4>2000 +</h4>
                                            <p className='mb-0'>Guests per year</p>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-3 d-flex justify-content-center align-items-center ' style={{ border: '2px solid #dfa974', height: '80px', width: '100%', flexDirection: 'column', gap: '5px' }}>
                                            <h4>2000 +</h4>
                                            <p className='mb-0'>Happy Clients</p>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-3 d-flex justify-content-center align-items-center ' style={{ border: '2px solid #dfa974', height: '80px', width: '100%', flexDirection: 'column', gap: '5px' }}>
                                            <h4>3 +</h4>
                                            <p className='mb-0'>Rooms</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
            {/*end of  our about*/}

            {/*start of  our client say*/}
            <section className='spad'>
                <div className='container'>
                    <div className='row  d-flex justify-content-center'>
                        <div className='col-lg-9' style={{ backgroundColor: '#262C31', width: '100%' }}>

                            <div className='row justify-content-center py-5' style={{ gap: '20px' }}>
                                <div className=' col-lg-12 col-md-12 col-sm-8  justify-content-center align-items-center' >
                                    <h3 className='text-white text-center'>What Our guest's Say</h3>
                                </div>

                                <div className=' col-lg-5 col-md-5 col-sm-8  justify-content-center align-items-center' style={{ height: '200px', backgroundColor: '#22272A', gap: '5px', }}>
                                    <p className='text-white  px-3 mt-3' style={{ fontSize: '15px' }}>
                                        clean and comfortable room, beautiful view over the ocean... Excellent hotel. Food was delicious, very calm and beautiful outside area with pool and garden
                                    </p>
                                    <div className='d-flex align-items-center px-3' style={{ gap: '5px' }}>
                                        <img src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp" className="rounded-circle" style={{ width: "40px" }}
                                            alt="Avatar" />
                                        <h5 className='text-white'>Elina</h5>
                                    </div>
                                </div>
                                <div className=' col-lg-5 col-md-5 col-sm-8  justify-content-center align-items-center' style={{ height: '200px', backgroundColor: '#22272A', gap: '5px', }}>
                                    <p className='text-white  px-3 mt-3' style={{ fontSize: '15px' }}>
                                        clean and comfortable room, beautiful view over the ocean... Excellent hotel. Food was delicious, very calm and beautiful outside area with pool and garden
                                    </p>
                                    <div className='d-flex align-items-center px-3' style={{ gap: '5px' }}>
                                        <img src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp" className="rounded-circle" style={{ width: "40px" }}
                                            alt="Avatar" />
                                        <h5 className='text-white'>Elina</h5>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </section>
            {/*end of  our clent say*/}


            {/*find of  with us */}
            <section className=''>
                <div className='container'>
                    <div className='row  d-flex justify-content-center'>
                        <div className='col-lg-6' style={{ width: '100%' }}>

                            <div className='row justify-content-center pb-5' style={{ gap: '20px' }}>
                                <div className=' col-lg-12 col-md-12 col-sm-8  justify-content-center align-items-center' >
                                    <h3 className=' text-center'>Find us on</h3>
                                </div>

                                <div className=' col-lg-8 col-md-5 col-sm-8 d-flex justify-content-center align-items-center' style={{ gap: '30px', }}>
                                    <img src='img/about/sco.png' width='60px' height='60px' />
                                    <a href='https://www.booking.com/hotel/lk/park-paradise-rumassala.en-gb.html' target="_blank"><img src='img/about/bco.png' width='60px' height='60px' /></a>
                                    <a href='https://www.facebook.com/share/1JWhNwMNwcpshrEa/?mibextid=LQQJ4d' target="_blank"><img src='img/about/fb.png' width='60px' height='60px' /></a>
                                    <a href='https://www.instagram.com/parkparadise_?igsh=MTV2NzZ1YmYzb2VreQ==' target="_blank"><img src='img/about/insta.png' width='60px' height='60px' /></a>

                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </section>
            {/*end find of  with us*/}
        </div>
    )
}

export default AboutUs