import React from "react";

function ThingsToDo() {
    return (
        <>
            <div className="container-fluid header-bg py-5 mb-5 wow fadeIn header_bg-thingtodo" data-wow-delay="0.1s">
                <div className="container py-5 d-flex justify-content-start" style={{flexDirection: 'column'}}>
                    <h3 className="display-4 text-white mb-3 animated slideInDown"
                        style={{fontSize: '40px'}}>Top things to do in Galle, Sri Lanka
                    </h3>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                            <li className="breadcrumb-item"><a className="text-white" href="/">News</a></li>
                            <li className="breadcrumb-item text-primary active" aria-current="page">Top things to do in
                                Galle, Sri Lanka
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className='spad3'>
                <div className='container'>
                    <div className='row  d-flex justify-content-center'>
                        <div className='col-lg-10 col-xl-9 col-md-12'>
                            <div className='row   justify-content-center'>
                                <div
                                    className='col-lg-12 col-md-12 col-sm-8 d-flex justify-content-start '
                                    style={{flexDirection: 'column', gap: '5px',}}>
                                    <h3 >Top things to do in Galle, Sri Lanka</h3>
                                    <img src='img/blog/blog-thingstodo.jpg' width='100%'/>
                                    <div className='d-flex flex-column' style={{gap: '5px'}}>
                                        <h5 className='fw-bold'>
                                            Galle fort
                                        </h5>
                                        <p>
                                            Galle Fort, located in the Bay of Galle on Sri Lanka's southwest coast, was
                                            first erected by the Portuguese in 1588, and then heavily strengthened by
                                            the Dutch from 1649 onwards. It is a historical, archaeological, and
                                            architectural heritage site that still has a polished aspect after more than
                                            432 years, thanks to the refurbishment effort by the Sri Lankan
                                            Archaeological Department. The Galle fort is hands-down one of the
                                            compulsory places to visit in Galle.
                                        </p>
                                    </div>
                                    {/*////*/}
                                    <div className='d-flex flex-column' style={{gap: '5px'}}>
                                        <h5 className='fw-bold'>
                                            Unawatuna Beach

                                        </h5>
                                        <p>
                                            Unawatuna is a beach town in Sri Lanka's Galle district and is a popular
                                            tourist destination in Sri Lanka because of its beach and coral reefs. It is
                                            a Galle suburb located 5 kilometres (3.1 miles) southeast of the city centre
                                            and 108 kilometres (67 miles) south of galle. Unawatuna stands at a height
                                            of 5 meters (16 feet) above sea level. Despite tremendous growth in the
                                            recent decade, the endangered and endemic purple-faced langur, a shy monkey
                                            species found exclusively in Sri Lanka's jungles, nevertheless calls
                                            Unawatuna home. A sea bath in Unawatuna is one of the refreshng things to do
                                            in Galle.
                                        </p>
                                    </div>

                                    {/*////*/}
                                    <div className='d-flex flex-column' style={{gap: '5px'}}>
                                        <h5 className='fw-bold'>
                                            Talpe Beach

                                        </h5>
                                        <p>
                                            Sri Lanka is recognized as the Indian Ocean's Pearl. Because it is
                                            surrounded by magnificent beaches. Talpe Beach is one of Sri Lanka's most
                                            beautiful beach destinations. The rockpool beach is another name for it. It
                                            is in Sri Lanka's Southern Province, near the town of Galle. It is a popular
                                            tourist spot for both locals and visitors.
                                        </p>
                                        <p>
                                            The rockpool beach is 40 meters from the major road between Matara and
                                            Galle. Talpe is 10 kilometres from Galle Town. To get to this lovely
                                            location, you can take a tuk-tuk or your private vehicle. Talpe is a
                                            peaceful village amid the bustling metropolis of Galle. Stilt fishermen
                                            abound in this seaside village, and it's one of the excellent places to
                                            visit in Galle if you want to learn more about the local way of life. The
                                            rock ponds were cut out of the corals some 50 years ago, according to the
                                            islanders, and were used to soak coconut husks to separate the coconut coir.
                                            The beach is in excellent condition, and the location is not overly exposed.
                                            You will undoubtedly enjoy it!
                                        </p>
                                    </div>


                                    {/*////*/}
                                    <div className='d-flex flex-column' style={{gap: '5px'}}>
                                        <h5 className='fw-bold'>
                                            Madol duwa Boat Safari and fish therapy


                                        </h5>
                                        <p>Located a few kilometres from Galle is the picturesque Koggala Lake. It is a
                                            haven for nature lovers who want to learn about and explore the natural
                                            biodiversity and environment. The lake is surrounded by mangrove swamps that
                                            are lush and verdant. In Koggala Lake, you may explore the entire region as
                                            well as all eight islands.
                                        </p>
                                        <p>
                                            Madol Duwa is a fictional place in The novel 'Madol Duwa,' written by the
                                            great novelist Martin Wickramasinghe, made the island more famous. Later,
                                            the village of Koggala was identified as that of the great novelist.
                                            Whale watching from Galle (by Srilanka Navy)
                                        </p>
                                        <p>
                                            Since 30 January 2011 at the port of Galle, the Sri Lanka Navy's passenger
                                            ship "Princess of Lanka" has set sail on a new journey as part of the "whale
                                            watching project." Thousands of security forces personnel, passengers, and
                                            supplies were transported between Trincomalee, Kankesanthurai ports, and
                                            Thalai Mannar on the Princess of Lanka passenger vessel until the mainland
                                            supply routes were cleared and restored after the dawn of peace. Whale
                                            watching is undoubtedly one of the most interesting things to do in Galle.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </section>

        </>
    )
}

export default ThingsToDo