import Slider from "react-slick";
import React, { useState } from "react";
import styled from "styled-components";
import {Col, Dropdown, DropdownButton} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderContainer = styled.div`
  .slick-slide > div {
    margin: 40px 10px;
  }
  .slick-dots {
    bottom: auto;
    top: -3px; /* Adjust this value to control the distance from the top */
    border: 1px sandybrown;
  }
  .slick-dots li button:before {
    font-size: 12px;
  }
  .slick-dots li.slick-active button:before {
    color: #b8a680;
  }
`;

function CalenderSlider() {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [month, setMonth] = useState(new Date().getMonth());
    const [year, setYear] = useState(new Date().getFullYear());

    const handleMonthChange = (selectedMonth) => {
        setMonth(selectedMonth);
    };

    const handleYearChange = (selectedYear) => {
        setYear(selectedYear);
    };

    const renderCalendar = () => {
        const months = Array.from({ length: 12 }, (_, i) => new Date(year, i));
        return months.map((monthDate, index) => (
            <Col key={index} className="mb-4">
                    <DatePicker
                        inline
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        highlightDates={[{ "react-datepicker__day--highlighted-custom-1": [] }]}
                        monthsShown={1}
                        minDate={new Date(year, monthDate.getMonth(), 1)}
                        maxDate={new Date(year, monthDate.getMonth() + 1, 0)}
                    />

            </Col>
        ));
    };

    return (
        <div>


            <SliderContainer style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>

                <Slider {...settings}>

                    {renderCalendar()}
                </Slider>
            </SliderContainer>
        </div>

    );
}

export default CalenderSlider;
