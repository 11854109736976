import Slider from "react-slick";
import React from "react";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';



const resturant = [
    {
        image: 'img/services/res-rom.jpg',
        title: 'Restaurant',
        description: 'Indulge in a delightful culinary experience at Park paradise.\n' +
            '                                                        the\n' +
            '                                                        signature restaurant at Park Paradise. Whether you\'re a guest\n' +
            '                                                        seeking convenient dining or a local resident looking for a\n' +
            '                                                        delectable meal, we welcome you with open arms.'
    },
    {
        image: 'img/services/res-food.jpg',
        title: 'Restaurant',
        description: 'Indulge in a delightful culinary experience at Park paradise.\n' +
            '                                                        the\n' +
            '                                                        signature restaurant at Park Paradise. Whether you\'re a guest\n' +
            '                                                        seeking convenient dining or a local resident looking for a\n' +
            '                                                        delectable meal, we welcome you with open arms.'
    },
    {
        image: 'img/services/res-food.jpg',
        title: 'Restaurant',
        description: 'Indulge in a delightful culinary experience at Park paradise.\n' +
            '                                                        the\n' +
            '                                                        signature restaurant at Park Paradise. Whether you\'re a guest\n' +
            '                                                        seeking convenient dining or a local resident looking for a\n' +
            '                                                        delectable meal, we welcome you with open arms.'
    }
]

function facilitySlider() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return(
       <div className='res-main-dive' >
           <Slider {...settings}>

               {resturant.map((item, index) => (
                   <div className='d-flex res-div ' >
                       <div className=''>
                           <img src={item.image} className='res-img' width='400px' height='400px' />
                       </div>
                           <div className='position-relative '>
                               <div className='facilities-detail   '>
                                   <h2 className='room-header'>
                                       {item.title}
                                   </h2>
                                   <p style={{marginTop: '5px'}}>
                                       {item.description}
                                   </p>
                                   <a href="#" className='facilities-btn '>Read More</a>
                               </div>


                       </div>

                   </div>
               ))}
           </Slider>
       </div>
   )
}
export default facilitySlider;