import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const breakfastItems = [
    {
        image: 'img/dishes/chicken-stew.jpg',
        title: 'Chicken Stew',
        description: 'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in,'
    },
    {
        image: 'img/dishes/prawn-tempura-04.jpg',
        title: 'Battered Prawns',
        description: 'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in,'
    },
    {
        image: 'img/dishes/cruds.png',
        title: 'Curd with Honey',
        description: 'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in,'
    },
    {
        image: 'img/dishes/seafood (2).jpg',
        title: 'Seafood Platter',
        description: 'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in,'
    },
    {
        image: 'img/dishes/Devilled-Sausages-SQ.jpg',
        title: 'Devilled Sausages',
        description: 'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in,'
    }
];
const lunchItems = [
    {
        image: 'img/seafood.jpg',
        title: 'Chicken Stew',
        description: 'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in,'
    },
    {
        image: 'img/dishes/prawn-tempura-04.jpg',
        title: 'Battered Prawns',
        description: 'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in,'
    },
    {
        image: 'img/dishes/cruds.png',
        title: 'Curd with Honey',
        description: 'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in,'
    },
    {
        image: 'img/dishes/seafood (2).jpg',
        title: 'Seafood Platter',
        description: 'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in,'
    },
    {
        image: 'img/dishes/Devilled-Sausages-SQ.jpg',
        title: 'Devilled Sausages',
        description: 'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in,'
    }
];
const dinnerItems = [
    {
        image: 'img/breakfast.png',
        title: 'Chicken Stew',
        description: 'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in,'
    },
    {
        image: 'img/dishes/prawn-tempura-04.jpg',
        title: 'Battered Prawns',
        description: 'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in,'
    },
    {
        image: 'img/dishes/cruds.png',
        title: 'Curd with Honey',
        description: 'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in,'
    },
    {
        image: 'img/dishes/seafood (2).jpg',
        title: 'Seafood Platter',
        description: 'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in,'
    },
    {
        image: 'img/dishes/Devilled-Sausages-SQ.jpg',
        title: 'Devilled Sausages',
        description: 'Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in,'
    }
];

function Slides() {
    const [selectedMenu, setSelectedMenu] = useState('Breakfast');

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const getMenuItems = () => {
        switch (selectedMenu) {
            case 'Breakfast':
                return breakfastItems;
            case 'Lunch':
                return lunchItems;
            case 'Dinner':
                return dinnerItems;
            default:
                return breakfastItems;
        }
    };

    const handleMenuClick = (menu, event) => {
        event.preventDefault();
        setSelectedMenu(menu);
    };

    return (
        <div className="container">
            <div className=' d-flex food-menu-time ' style={{padding:'0px', justifyContent:'center', marginTop:'50px', textAlign:'center'}} >
                <a href="#" className='service_btn-food' onClick={(event) => handleMenuClick('Breakfast', event)}>Breakfast</a>
                <a href="#" className='service_btn-food' onClick={(event) => handleMenuClick('Lunch', event)}>Lunch</a>
                <a href="#" className='service_btn-food' onClick={(event) => handleMenuClick('Dinner', event)}>Dinner</a>
            </div>
            <Slider {...settings}>
                {getMenuItems().map((item, index) => (
                    <div key={index} className='food-menu' style={{ padding: '10px' }}>
                        <div style={{ width: '100%',height:'250px' }}>
                            <img width='100%' height='100%' src={item.image} alt={item.title} />
                        </div>
                        <div style={{ backgroundColor: 'white', padding: '20px', textAlign: 'center' }}>
                            <h5 className='mt-1'>{item.title}</h5>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default Slides;