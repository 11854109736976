import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import emailjs from 'emailjs-com';

function Contact() {

    const location = useLocation();

    const bookingDetails = location.state || {}; // Get the passed state

    const { checkIn, checkOut, adults, children, room } = bookingDetails;

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        startDate: checkIn,
        endDate: checkOut,
        adults: adults,
        room: room,
        children: children,
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send(
            'service_kp7b6xj',       // Replace with your EmailJS service ID
            'template_smigkhf',      // Replace with your EmailJS template ID
            formData,
            'TbZcVEvka6Gbk6w0m'      // Replace with your EmailJS user ID
        )
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Message sent successfully!');
            })
            .catch((err) => {
                console.error('FAILED...', err);
                alert('Failed to send message. Please try again later.');
            });

        setFormData({
            name: '',
            email: '',
            phone: '',
            startDate: checkIn,
            endDate: checkOut,
            adults: adults,
            children: children,
            room: room,
            message: '',
        });
    };

    return (
        <>
            <div className="container-fluid header-bg py-5 mb-5 wow fadeIn header_bg-about5" data-wow-delay="0.1s">
                <div className="container py-5 d-flex justify-content-start" style={{ flexDirection: 'column' }}>
                    <h3 className="display-4 text-white mb-3 animated slideInDown"
                        style={{ fontSize: '40px' }}>Contact</h3>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                            <li className="breadcrumb-item text-primary active" aria-current="page">Contact</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <section className='spad3'>
                <div className='container'>
                    <div className='row  d-flex justify-content-center'>
                        <div className='col-lg-10 col-xl-8 col-md-12'>
                            <div className='row   justify-content-center'>
                                <div className='col-lg-12 col-md-12 col-sm-8 d-flex justify-content-start align-items-center' style={{ flexDirection: 'row', gap: '5px', }}>
                                    <div className='row'>

                                        <div className='col-lg-6 d-flex ' style={{ flexDirection: 'column' }}>
                                            <h2 className='px-5'>
                                                We Love to Hear from You
                                            </h2>
                                        </div>
                                        <div className='col-lg-6  justify-content-center'>
                                            <p style={{ fontSize: '15px' }}>
                                                At Park Paradise, we're committed to providing exceptional service and
                                                ensuring a memorable stay for our guests. We encourage you to reach out
                                                to us if you have any questions, require assistance, or simply want to
                                                learn more about what we offer.


                                            </p>
                                            <p style={{ fontSize: '15px' }}>
                                                For your convenience, submit your inquiry directly through our secure
                                                online contact form. A member of our team will respond to your message
                                                within 24 hours.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className=' col-lg-12  col-md-12 col-sm-8 justify-content-start ' style={{
                                    height: '', gap: '5px',
                                }}>

                                    <div className='w-100  contact-sec justify-content-center'>
                                        <div className='row py-4 h-100 justify-content-center  ' style={{ gap: '10px' }}>
                                            <div className="col-lg-3 col-sm-8 col-md-3 con-div  bg-white d-flex flex-column justify-content-center" style={{ opacity: '0.9', gap: '10px' }}>
                                                <h4>Our Address</h4>
                                                <div>

                                                    <p className='mb-0'>Yaddehimulla, Rumassala, Unawatuna,</p>
                                                    <p className=''>Sri Lanka</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-sm-8  col-md-3 con-div  bg-white d-flex flex-column justify-content-center" style={{ opacity: '0.9', gap: '10px' }}>
                                                <h4>Our Address</h4>
                                                <h6 style={{ color: '#b8a680' }}>
                                                    (+94) 75 610 6173
                                                </h6>
                                                <p className='con-email'
                                                >
                                                    info@parkparadise@gmail.com
                                                </p>
                                            </div>
                                            <div className="col-lg-5 col-sm-8 col-md-5 con-div  bg-white d-flex flex-column justify-content-center"
                                                style={{ opacity: '0.9', gap: '10px' }}>
                                                <h4>Our Address</h4>

                                                <div className="form-floating">
                                                    <input type="email" className="form-control" id="email" name='email'
                                                        placeholder="Your email Address" />
                                                </div>
                                                <a href="#" className='facilities-btn '>Subscribe</a>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </section>

            {/*Contact Section Begin*/}
            <section className="contact-section spad" id="cform">
                <div className="container d-flex justify-content-center">
                    <div className='col-lg-10 col-xl-8 col-md-12'>

                        <div className="row ">
                            <div className="col-lg-5">
                                <div className="contact-text">
                                    <h2>Get in touch </h2>
                                    <h5>we believe in fostering a connection with our guests. Let us know how we can make your stay exceptional!</h5>
                                    <p className='mt-4' style={{ lineHeight: '18px', fontSize: '15px' }}>Don't hesitate to reach out to our concierge team for personalized recommendations on things to do and see in the area, restaurant reservations, or assistance with local transportation.</p>
                                </div>
                            </div>
                            <div className="col-lg-7 ">
                                <form action="#" className="contact-form" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-6">
                                        <label htmlFor="name">Your Name</label>
                                            <input type="text"
                                                placeholder="Your Name"
                                                id="name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                        <label htmlFor="name">Your Email</label>
                                            <input type="email"
                                                placeholder="Your Email"
                                                id="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                        <label htmlFor="name">Start Date</label>
                                            <input type="text" placeholder="Start Date"
                                                id="startDate"
                                                name="startDate"
                                                value={formData.startDate}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                        <label htmlFor="name">End Date</label>
                                            <input type="text" placeholder="End Date"
                                                id="endDate"
                                                name="endDate"
                                                value={formData.endDate}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                        <label htmlFor="name">Selected Room</label>
                                            <input type="text"
                                                placeholder="selected Room"
                                                id="room"
                                                name="room"
                                                value={formData.room}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                        <label htmlFor="name">No of adults</label>
                                            <input type="text"
                                                placeholder="Adults"
                                                id="adults"
                                                name="adults"
                                                value={formData.adults}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                        <label htmlFor="name">No of children</label>
                                            <input type="text" placeholder="children"
                                                id="children"
                                                name="children"
                                                value={formData.children}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                        <label htmlFor="name">Your Phone</label>
                                            <input type="text" placeholder="Your Phone"
                                                id="phone"
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-12">
                                            <textarea name="message" id="message" placeholder="Your Message" value={formData.message} onChange={handleChange}></textarea>
                                            <button type="submit">Submit Now</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="map">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3967.8538376901492!2d80.23495327372643!3d6.014797328868369!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae173005278bffd%3A0x786a4161dc12f4c9!2sPark%20Paradise%20Rumassala!5e0!3m2!1sen!2slk!4v1721111440446!5m2!1sen!2slk"
                                height="470" style={{ border: 0 }} allowfullscreen=""></iframe>
                        </div>
                    </div>
                </div>
            </section>
            {/* Contact Section End*/}

        </>
    )
}

export default Contact
